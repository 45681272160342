<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto max-w-sm">
      <div class="my-8">
        <form @submit.prevent="handleContinue">
          <input-field
            v-model="formData.personalNumber"
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            maxlength="11"
            label="Personnummer"
            class="mb-4"
            centered />
          <span class="text-xs text-muted mt-2 mb-4 block">11 siffer</span>
        </form>
      </div>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  methods: {
    handleContinue () {
      if (this.isValid) {
        this.$emit("continue")
      }
    }
  },

  computed: {
    modelErrors () {
      return validate.single(this.formData.personalNumber, {
        presence: true,
        type: "string",
        length: {
          is: 11
        }
      })
    },

    isValid () {
      return (typeof this.modelErrors === "undefined")
    }
  }
}
</script>
